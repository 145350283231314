// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headings-links-border {
  border-bottom: 2px solid #75b951;
}

.headings-links-container {
  padding-top: 0.5%;
  padding-bottom: 0.75%;
  padding-left: 20px;
}

.home-link,
.home-link:visited {
  color: #75b951;
  font-size: 36px;
  font-weight: bolder;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
  text-decoration: none;
  padding-right: 5%;
  transition: color 0.7s;
}

.about-link,
.about-link:visited,
.contact-link,
.contact-link:visited,
.projects-link,
.projects-link:visited,
.github-link,
.github-link:visited {
  color: #75b951;
  font-size: 28px;
  padding-left: 3%;
  text-decoration: none;
  padding-bottom: 4px;
  transition: color 0.7s;
}

.about-link {
  padding-left: 3%;
  padding-right: 0%;
}

.home-link:hover,
.about-link:hover,
.contact-link:hover,
.projects-link:hover,
.github-link:hover {
  color: #bbf899;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Navbar.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;;EAEE,cAAc;EACd,eAAe;EACf,mBAAmB;EACnB,gDAAgD;EAChD,gBAAgB;EAChB,qBAAqB;EACrB,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;;;;;;;;EAQE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,qBAAqB;EACrB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;;;;;EAKE,cAAc;AAChB","sourcesContent":[".headings-links-border {\r\n  border-bottom: 2px solid #75b951;\r\n}\r\n\r\n.headings-links-container {\r\n  padding-top: 0.5%;\r\n  padding-bottom: 0.75%;\r\n  padding-left: 20px;\r\n}\r\n\r\n.home-link,\r\n.home-link:visited {\r\n  color: #75b951;\r\n  font-size: 36px;\r\n  font-weight: bolder;\r\n  font-family: Verdana, Geneva, Tahoma, sans-serif;\r\n  text-align: left;\r\n  text-decoration: none;\r\n  padding-right: 5%;\r\n  transition: color 0.7s;\r\n}\r\n\r\n.about-link,\r\n.about-link:visited,\r\n.contact-link,\r\n.contact-link:visited,\r\n.projects-link,\r\n.projects-link:visited,\r\n.github-link,\r\n.github-link:visited {\r\n  color: #75b951;\r\n  font-size: 28px;\r\n  padding-left: 3%;\r\n  text-decoration: none;\r\n  padding-bottom: 4px;\r\n  transition: color 0.7s;\r\n}\r\n\r\n.about-link {\r\n  padding-left: 3%;\r\n  padding-right: 0%;\r\n}\r\n\r\n.home-link:hover,\r\n.about-link:hover,\r\n.contact-link:hover,\r\n.projects-link:hover,\r\n.github-link:hover {\r\n  color: #bbf899;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
