// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-slider {
  width: 80%;
  margin: auto;
  text-align: center;
}
.image-slider .slick-dots {
  bottom: -30px;
}
.image-slider img {
  max-width: 100%;
  height: auto;
  margin: auto;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ImageSlider.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,aAAa;AACf;AACA;EACE,eAAe;EACf,YAAY;EACZ,YAAY;AACd","sourcesContent":[".image-slider {\r\n  width: 80%;\r\n  margin: auto;\r\n  text-align: center;\r\n}\r\n.image-slider .slick-dots {\r\n  bottom: -30px;\r\n}\r\n.image-slider img {\r\n  max-width: 100%;\r\n  height: auto;\r\n  margin: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
