// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#msg404 {
  margin: 1em;
  color: #75b951;
  font-family: Verdana, Tahoma, sans-serif;
  font-size: 54px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/404.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,cAAc;EACd,wCAAwC;EACxC,eAAe;AACjB","sourcesContent":["#msg404 {\r\n  margin: 1em;\r\n  color: #75b951;\r\n  font-family: Verdana, Tahoma, sans-serif;\r\n  font-size: 54px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
